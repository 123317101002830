export const CHAT_AREA = {
  HEADER_HEIGHT: 48,
  HEIGHT: 609,
  ALL_HEIGHT: 653,
  TEXT_AREA_HEIGHT: 120,
  MAX_WIDTH_WITH_SIDE_BAR: 248,
  MAX_WIDTH_WITHOUT_SIDE_BAR: 488,
} as const;

export const API_PASS = {
  LOGIN: "login",
  GET_GROUP: "group/list",
  SPEECH: "speech",
  GET_SPEECH: "speech/list",
} as const;

export const PATH = {
  CHAT: "/",
  LOGIN: "/login",
  SHOP_LOGIN: "/shop-login",
  SETTING: "setting",
} as const;

// システム変数
export const VARIABLE = {
  VOYT_USER_ID: "voyt_user_id",
  VOYT_PASSWORD: "voyt_user_password",
} as const;

export const FRAME_SEND_ACTION = {
  INTERCOM_RESIZE: "intercom_resize",
  INTERCOM_UNREAD_MESSAGES_NUM: "intercom_unread_messages_num",
  INTERCOM_CLOSE: "intercom_close",
  INTERCOM_SIDEBAR_OPEN: "intercom_sidebar_open",
  INTERCOM_SIDEBAR_CLOSE: "intercom_sidebar_close",
  INTERCOM_SEND_MESSAGE: "intercom_send_message",
  // 送信後の短い時間で返信を受信した
  INTERCOM_QUICK_REPLY_RECEIVED: "intercom_quick_reply_received",
} as const;

export const FRAME_RECEIVE_ACTION = {
  CHANGE_STATUS: "intercom_change_status",
  INTERCOM_OPEN: "intercom_open",
  INTERCOM_CLOSE: "intercom_close",
} as const;

export const ApproveFrameReceiveAction = [
  FRAME_RECEIVE_ACTION.CHANGE_STATUS,
  FRAME_RECEIVE_ACTION.INTERCOM_OPEN,
  FRAME_RECEIVE_ACTION.INTERCOM_CLOSE,
];

export const RURA_WEB_HEADER_HEIGHT = 10;

export const MESSAGE_TEXT_LIMIT = 1000;

export const DELETE_CHAT_MESSAGE_TEXT = "メッセージを削除しました。";

export const TEXT = {
  LOGIN: {
    ERROR_SIGNIN_FAILED: "スタッフIDまたはパスワードが間違っています。",
    ERROR_SIGNIN_FROZEN:
      "ログイン試行回数制限を迎えました。管理者に連絡してください。",
    ERROR_SIGNIN_REJECTION: "ログインが許可されたIPアドレスではありません。",
    ERROR_COLLABO_CUSTOM_VARIABLE: "インカムユーザの情報が不足しています。",
  },
  MESSAGE: {
    CHARACTER_COUNT_OVER_ALERT: "文字数を1000文字以下にしてください",
    CHARACTER_COUNT_OVER_ON_CHAT_MESSAGE_AREA:
      "※メッセージは1000文字以内にしてください。",
    ERROR_MESSAGE_AREA: "エラーが発生しました。再度ログインしてください。",
    NEW_MESSAGE_TEXT: "※新着メッセージがあります!",
    SYSTEM_NOTIFICATION:
      "今後もより快適にRURAをご利用いただくために改善リクエストを受け付けています！\n　\n※お送りいただいた内容への個別の返信はしておりません。\n※お急ぎの場合やサポートが必要な場合は、お電話にてご連絡いただけますようお願いいたします。（カスタマーサポート：070-1424-4842）",
  },
  COMMON: {
    HEADER_OPERATION_DESCRIPTION: "入力して接続開始を押してください。",
  },
  TOOLTIP: {
    TURN_OFF_NOTIFICATION_SOUND_TEXT: "通知音をOFFにします",
    TURN_ON_NOTIFICATION_SOUND_TEXT: "通知音をONにします",
    TURN_OFF_TOAST_TEXT: "通知をOFFにします",
    TURN_ON_TOAST_TEXT: "通知をONにします",
  },
  ERROR: {
    ERROR_VOYT_API: {
      TITLE: "インカムアプリにログインができません",
      SUGGEST: "RURA MANAGEMENTにてインカムアカウントを確認してください。",
    },
    ERROR_VOYT_API_NO_GROUP: {
      TITLE: "表示できるメッセージがありません",
      SUGGEST: "インカムアプリにてインカムアカウント設定を確認してください。",
    },
  },
};

export const API_FUNCTION = {
  FEEDBACK: "chat_feedback",
} as const;
