import { Box, Flex, HStack, Image, Link, Text } from "@chakra-ui/react";
import { FC } from "react";
import { CHAT_AREA, DELETE_CHAT_MESSAGE_TEXT } from "src/const";
import { Chat, SendUserRoleType } from "src/interface/Chat";
import { isURL } from "src/util/getString";
import { transformChatTime } from "src/util/getTime";
import { BiSolidUserCircle } from "react-icons/bi";
import { ChatText } from "./ChatText";

export const OtherChatMessage: FC<{
  chat: Chat;
  icon: string | undefined | null;
  isSideBar: boolean;
}> = ({ chat, icon, isSideBar }) => {
  return (
    <Flex alignItems="flex-end">
      <Flex mt="0px" width="100%" gap="8px">
        <Box>
          <Box>
            {icon ? (
              <Image w="40px" h="40px" borderRadius="80%" src={icon ?? ""} />
            ) : (
              <Box m="-4px">
                <BiSolidUserCircle color="rgba(34, 197, 94, 1)" size="48px" />
              </Box>
            )}
          </Box>
          <Box mt="8px">
            <Text
              textAlign="center"
              fontSize="12px"
              fontWeight={600}
              color={
                chat?.userRole === SendUserRoleType.RemoteUser
                  ? "rgba(0, 160, 233, 1)"
                  : "rgba(93, 195, 104, 1)"
              }
            >
              {chat?.userRole === SendUserRoleType.RemoteUser ? "遠隔" : "店舗"}
            </Text>
          </Box>
        </Box>
        <Box
          maxWidth={
            isSideBar
              ? CHAT_AREA.MAX_WIDTH_WITH_SIDE_BAR
              : CHAT_AREA.MAX_WIDTH_WITHOUT_SIDE_BAR
          }
        >
          <Flex>
            <Text
              textAlign="left"
              fontSize="14px"
              fontWeight={600}
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              overflow="hidden"
            >
              {chat?.intercomAccount?.name}
            </Text>
            <Box m="1px 0px 0px 8px">
              {chat.userType === "user" && (
                <Text fontSize="12px" fontWeight={300}>
                  {transformChatTime(chat.timestamp)}
                </Text>
              )}
            </Box>
          </Flex>
          <Box position="relative" mt="8px">
            <HStack display="inline-block" alignItems="flex-end">
              {chat.text === DELETE_CHAT_MESSAGE_TEXT ? (
                <Box
                  p="8px 10px 0px 10px"
                  borderRadius="8px 0px 8px 8px"
                  textAlign="left"
                >
                  <Text fontSize="13px" color="#1A202C" wordBreak="break-all">
                    {DELETE_CHAT_MESSAGE_TEXT}
                  </Text>
                </Box>
              ) : (
                <Box
                  background={
                    chat.userType === "user"
                      ? "rgba(238, 240, 241, 1)"
                      : "rgba(238, 240, 241, 1)"
                  }
                  p="8px 10px"
                  borderRadius="0px 8px 8px 8px"
                  textAlign="left"
                  width="100%"
                >
                  {isURL(chat.text) ? (
                    <Link href={chat.text} isExternal>
                      <Text
                        fontSize="13px"
                        color="blue.500"
                        wordBreak="break-all"
                      >
                        {chat.text}
                      </Text>
                    </Link>
                  ) : (
                    <ChatText text={chat.text} />
                  )}
                </Box>
              )}
            </HStack>
          </Box>
        </Box>
      </Flex>
    </Flex>
  );
};
