import { Box, Flex, HStack, Link, Text, Image } from "@chakra-ui/react";
import { FC } from "react";
import { CHAT_AREA, DELETE_CHAT_MESSAGE_TEXT } from "src/const";
import { isURL } from "src/util/getString";
import { transformChatTime } from "src/util/getTime";
import { Chat, SendUserRoleType } from "src/interface/Chat";
import { BiSolidUserCircle } from "react-icons/bi";
import { ChatText } from "./ChatText";

export const MyChatMessage: FC<{
  chat: Chat;
  icon: string | undefined | null;
  isSideBar: boolean;
}> = ({ chat, icon, isSideBar }) => {
  return (
    <Flex
      alignItems="flex-end"
      display="flex"
      width="100%"
      justifyContent="flex-end"
      position="relative"
      p="0px"
    >
      <HStack
        alignItems="flex-start"
        justifyContent="flex-end"
        mt="0px"
        position="relative"
      >
        <Box maxWidth="100%">
          <Flex pb="8px" justifyContent={"flex-end"}>
            <Box m="1px 8px 0px 0px">
              {chat.userType === "user" && (
                <Text fontSize="12px" fontWeight={300}>
                  {transformChatTime(chat.timestamp)}
                </Text>
              )}
            </Box>
            <Text
              textAlign="left"
              fontSize="14px"
              fontWeight={600}
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              overflow="hidden"
            >
              {chat?.intercomAccount?.name}
            </Text>
          </Flex>
          <Flex height="100%" justifyContent={"flex-end"}>
            {chat.text === DELETE_CHAT_MESSAGE_TEXT ? (
              <Box
                p="8px 10px 0px 10px"
                ml="-2px"
                borderRadius="8px 0px 8px 8px"
                textAlign="left"
                maxWidth={
                  isSideBar
                    ? CHAT_AREA.MAX_WIDTH_WITH_SIDE_BAR
                    : CHAT_AREA.MAX_WIDTH_WITHOUT_SIDE_BAR
                }
              >
                <Text fontSize="13px" color="#1A202C" wordBreak="break-all">
                  {DELETE_CHAT_MESSAGE_TEXT}
                </Text>
              </Box>
            ) : (
              <Box
                background={
                  chat.userType === "user"
                    ? "rgba(233, 249, 239, 1)"
                    : "rgba(0,0,0, 0.3)"
                }
                p="8px 10px 8px 10px"
                borderRadius="8px 0px 8px 8px"
                textAlign="left"
                maxWidth={
                  isSideBar
                    ? CHAT_AREA.MAX_WIDTH_WITH_SIDE_BAR
                    : CHAT_AREA.MAX_WIDTH_WITHOUT_SIDE_BAR
                }
              >
                {isURL(chat.text) ? (
                  <Link href={chat.text} isExternal>
                    <Text
                      fontSize="13px"
                      color="blue.500"
                      wordBreak="break-all"
                    >
                      {chat.text}
                    </Text>
                  </Link>
                ) : (
                  <ChatText text={chat.text} />
                )}
              </Box>
            )}
          </Flex>
        </Box>
        <Box>
          <Box>
            {icon ? (
              <Image w="40px" h="40px" borderRadius="80%" src={icon ?? ""} />
            ) : (
              <Box m="-4px">
                <BiSolidUserCircle
                  color="rgba(34, 197, 94, 1)"
                  fontSize="48px"
                />
              </Box>
            )}
          </Box>
          <Box mt="8px">
            <Text
              textAlign="center"
              fontSize="12px"
              fontWeight={600}
              color={
                chat?.userRole === SendUserRoleType.RemoteUser
                  ? "rgba(0, 160, 233, 1)"
                  : "rgba(93, 195, 104, 1)"
              }
            >
              {chat?.userRole === SendUserRoleType.RemoteUser ? "遠隔" : "店舗"}
            </Text>
          </Box>
        </Box>
      </HStack>
    </Flex>
  );
};
